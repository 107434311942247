import React from 'react';
import { Box } from '@mui/material';

interface WrapperProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
  fullWidth?: boolean;
}

const Wrapper = ({ style, children, fullWidth }: WrapperProps): JSX.Element => {
  return (
    <Box
      width="100%"
      maxWidth={!fullWidth ? '1328px' : undefined}
      mx="auto"
      display="flex"
      flex="1 0 0"
      sx={
        !fullWidth
          ? {
              px: {
                xs: 0,
                lg: 5,
              },
            }
          : {}
      }
    >
      <Box
        flex="1 0 0"
        minWidth={0}
        sx={{
          borderLeft: {
            lg: `1px solid`,
          },
          borderRight: {
            lg: '1px solid',
          },
          borderColor: {
            lg: 'divider',
          },
        }}
        style={style}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Wrapper;
