import LayoutHeaderFooter from '@components/layout-header-footer';
import { Tab } from '@components/router-tabs';
import React, { useMemo } from 'react';
import { InternalToolPaths } from '../types/routers';
import Icon from '@components/icon';
import { useLocation } from 'react-router-dom';

const internalToolTabs: Tab[] = [
  {
    key: 'overview',
    label: 'OVERVIEW',
    to: `${InternalToolPaths.Overview}/${InternalToolPaths.OverviewMain}`,
  },
  {
    key: 'approvalHub',
    label: 'APPROVAL HUB',
    to: `${InternalToolPaths.Overview}/${InternalToolPaths.ApprovalHub}`,
  },
  {
    key: 'recommendationSettings',
    label: 'RECOMMENDATION SETTINGS',
    to: `${InternalToolPaths.Overview}/${InternalToolPaths.RecommendationsSettings}`,
  },
];

const configurationsTabs: Tab[] = [
  {
    key: 'banners',
    label: 'Banners',
    to: `${InternalToolPaths.EdgeConfiguration}/${InternalToolPaths.Banners}`,
  },
  {
    key: 'metrics',
    label: 'Metrics',
    to: `${InternalToolPaths.EdgeConfiguration}/${InternalToolPaths.Metrics}`,
  },
];

const navIconStyles = {
  height: 24,
  width: 24,
};

const leftNavItems = [
  {
    key: 'overview',
    to: InternalToolPaths.Overview,
    label: 'Overview',
    icon: <Icon icon="bubble-race" style={navIconStyles} />,
  },
  {
    key: 'settings',
    to: InternalToolPaths.EdgeConfiguration,
    label: 'Configurations',
    icon: <Icon icon="settings-alt" style={navIconStyles} />,
  },
];

export const InternalToolLayout = () => {
  const location = useLocation();

  const tabs = useMemo(() => {
    if (location.pathname.includes(InternalToolPaths.EdgeConfiguration)) {
      return configurationsTabs;
    }
    return internalToolTabs;
  }, [location.pathname]);

  return <LayoutHeaderFooter tabs={tabs} leftNavTabs={leftNavItems} fullWidth={true} />;
};
