import { Grid2Props, Tooltip, Typography, useTheme } from '@mui/material';
import Grid from '@mui/system/Grid';
import InfoCard from '@components/info-card';
import React, { ReactNode, useMemo } from 'react';
import { usePublicConfig } from '@components/public-config/public-config';
import { formatAmount } from '@utils/formatters';
import { ApiEndpoint } from '@api/types';
import { ApiRequestParams, useApiRequest } from '@api/use-api-request';
import {
  calculateTotalAssets,
  calculateTotalBlockchains,
  calculateTotalProtocols,
  calculateTotalValueSecured,
  calculateTotalVolumeSecured,
} from '@pages/landing/utils';

export const MetricsCards = () => {
  const theme = useTheme();
  const { publicConfig } = usePublicConfig();

  const { response: metricsData, isLoading: metricsLoading } = useApiRequest({
    endpoint: ApiEndpoint.EdgeMetrics,
  });
  const apiFeedsRequestParams: ApiRequestParams<ApiEndpoint.Feeds> = useMemo(
    () => ({
      endpoint: ApiEndpoint.Feeds,
    }),
    []
  );
  const { response: feedsResponse } = useApiRequest(apiFeedsRequestParams);

  const networks = useMemo(() => {
    return Array.from(
      new Set(
        (feedsResponse?.feeds || [])
          .filter((f) => f.type === 'push')
          .map((f) => f.network?.toLowerCase())
          .filter(Boolean) as string[]
      )
    );
  }, [feedsResponse]);

  const metric: {
    title: string;
    value: ReactNode;
    size?: Grid2Props['size'];
    borderRight?: { xs: boolean; lg: boolean };
    borderBottom?: { xs: boolean; lg: boolean };
    tooltip?: string;
  }[] = useMemo(() => {
    const tvl = calculateTotalValueSecured(metricsData, publicConfig);

    const totalVolumeSecured = calculateTotalVolumeSecured(metricsData, publicConfig);

    const chainsNumber = calculateTotalBlockchains(metricsData, networks, publicConfig);

    const assetsNumber = calculateTotalAssets(metricsData, publicConfig);

    const protocolsNumber = calculateTotalProtocols(metricsData, publicConfig);

    return [
      {
        title: 'Total Value Secured',
        value: (
          <Tooltip
            placement="bottom-start"
            title={formatAmount(tvl, {
              isCurrency: true,
              notation: 'standard',
            })}
          >
            <Typography variant="h3">
              {formatAmount(tvl, {
                isCurrency: true,
                notation: 'compact',
              })}
            </Typography>
          </Tooltip>
        ),
        size: { xs: 12, lg: 4 },
        borderRight: { xs: false, lg: true },
        borderBottom: { xs: true, lg: true },
      },
      {
        title: 'Total Volume Secured',
        value: (
          <Tooltip
            placement="bottom-start"
            title={formatAmount(totalVolumeSecured, {
              isCurrency: true,
              notation: 'standard',
            })}
          >
            <Typography variant="h3">
              {formatAmount(totalVolumeSecured, {
                isCurrency: true,
                notation: 'compact',
              })}
            </Typography>
          </Tooltip>
        ),
        size: { xs: 12, lg: 4 },
        borderRight: { xs: false, lg: true },
        borderBottom: { xs: true, lg: true },
        tooltip: 'Cumulative volume of all assets secured by the Edge Network',
      },
      {
        title: 'Number Of Blockchains',
        value: formatAmount(chainsNumber, {
          isCurrency: false,
          notation: 'standard',
        }),
        size: { xs: 12, lg: 4 },
        borderRight: { xs: false, lg: false },
        borderBottom: { xs: true, lg: true },
      },
      {
        title: 'Number Of Protocols Secured',
        value: formatAmount(protocolsNumber, {
          isCurrency: false,
          notation: 'standard',
        }),
        size: { xs: 12, lg: 6 },
        borderRight: { xs: false, lg: true },
        borderBottom: { xs: true, lg: false },
      },
      {
        title: 'Number Of Assets Secured',
        value: formatAmount(assetsNumber, {
          isCurrency: false,
          notation: 'standard',
        }),
        size: { xs: 12, lg: 6 },
        borderRight: { xs: false, lg: false },
        borderBottom: { xs: false, lg: false },
      },
    ];
  }, [publicConfig.metrics, metricsData, networks]);

  return (
    <Grid
      container
      sx={{ width: '100%' }}
      borderTop="1px solid"
      borderColor="divider"
      bgcolor="bg.paper"
    >
      {metric.map((m) => (
        <Grid
          size={m.size}
          py={2}
          px={4}
          key={m.title}
          borderRight={{
            xs: m.borderRight?.xs ? `1px solid ${theme.palette.divider}` : 0,
            lg: m.borderRight?.lg ? `1px solid ${theme.palette.divider}` : 0,
          }}
          borderBottom={{
            xs: m.borderBottom?.xs ? `1px solid ${theme.palette.divider}` : 0,
            lg: m.borderBottom?.lg ? `1px solid ${theme.palette.divider}` : 0,
          }}
        >
          <InfoCard
            title={m.title}
            isLoading={metricsLoading}
            titleSx={{
              color: 'primary.main',
            }}
            tooltip={m.tooltip}
          >
            {m.value}
          </InfoCard>
        </Grid>
      ))}
    </Grid>
  );
};
