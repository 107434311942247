import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { startCase, toLower } from 'lodash';

dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const userLocale =
  navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

export const formatDate = (date?: Date | string | number, dateFormat?: string): string => {
  if (!date) {
    return '';
  }

  return dayjs(date).format(dateFormat);
};

export const formatPercent = (
  value: number,
  { minimumFractionDigits = 0, maximumFractionDigits = 2 } = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }
): string =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);

export const getFractionDigits = (
  number: number,
  notation?: string,
  isPercent?: boolean
): number => {
  const fractionalPart = Math.abs(number) - Math.floor(Math.abs(number));
  const fractionSignificantDigits = -Math.floor(Math.log10(fractionalPart % 1));

  switch (true) {
    case !isPercent && Math.abs(number) < 10 && notation === 'compact':
      return 4 + Math.min(15, fractionSignificantDigits);
    case isPercent && Math.abs(number) < 1 && notation === 'compact':
      return Math.min(15, fractionSignificantDigits);
    case Math.abs(number) < 10 && notation === 'standard':
      return 5 + Math.min(15, fractionSignificantDigits);
    case notation === 'compact' || Math.abs(number) < 100:
      return 2;
    case Math.abs(number) < 1000:
      return 2;
    default:
      return 2;
  }
};

export type FormatAmountConfig = {
  isPercent?: boolean;
  isCurrency?: boolean;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  notation?: 'compact' | 'standard' | 'scientific' | 'engineering' | undefined;
  roundFloor?: boolean;
};

export const formatAmount = (
  amount: number,
  {
    isPercent,
    isCurrency,
    notation = 'compact',
    roundFloor,
    minimumFractionDigits = 0,
    maximumFractionDigits = isPercent
      ? getFractionDigits(amount * 100, notation, isPercent)
      : getFractionDigits(amount, notation),
  }: FormatAmountConfig = {}
) =>
  new Intl.NumberFormat('en-US', {
    style: isPercent ? 'percent' : isCurrency ? 'currency' : undefined,
    notation: Math.abs(amount) <= Number.MAX_SAFE_INTEGER ? notation : 'scientific',
    currency: isCurrency ? 'USD' : undefined,
    maximumFractionDigits,
    minimumFractionDigits,
    roundingMode: roundFloor ? 'floor' : undefined,
  }).format(amount);

export const capitalizeFirstLetter = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
};

export function truncateMiddle(
  text: string,
  frontChars: number = 4,
  backChars: number = 4,
  separator = '...'
) {
  if (text.length <= frontChars + backChars) {
    return text;
  }
  return text.slice(0, frontChars) + separator + text.slice(-backChars);
}

export const formatDay = (date?: Date, isFullDate?: boolean): string => {
  if (!date) return '';
  const dayjsDate = dayjs(date);
  const today = dayjsDate.isToday() && 'Today';
  const yesterday = dayjsDate.isYesterday() && 'Yesterday';
  const actualDate = date.toLocaleString(userLocale, { month: '2-digit', day: '2-digit' });
  if (isFullDate) return actualDate;
  return `${today || yesterday || actualDate}`;
};

export const formatTitle = (title: string) => startCase(toLower(title));
