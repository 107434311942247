import Icon from '@components/icon';
import {
  Box,
  Skeleton,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

interface InfoCardProps {
  title?: string;
  tooltip?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  titleSx?: TypographyProps['sx'];
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.bg.tooltip,
    color: theme.palette.txt.tooltip,
    boxShadow: 'none',
    fontSize: 14,
    lineHeight: '24px',
    padding: 4,
    borderRadius: 8,
    margin: 0,
  },
}));

const InfoCard = ({ title, children, tooltip, isLoading, titleSx }: InfoCardProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        component="p"
        variant="label"
        display="flex"
        columnGap={1}
        marginBottom={1}
        sx={titleSx}
      >
        {title}
        {tooltip && (
          <StyledTooltip
            title={tooltip}
            placement="right"
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement="right"] .${tooltipClasses.tooltip}`]:
                    {
                      margin: '0 0 0 -24px',
                    },
                },
              },
            }}
          >
            <Box display="flex" component="span">
              <Icon icon="info-circle" color={theme.palette.icon.secondary} />
            </Box>
          </StyledTooltip>
        )}
      </Typography>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Typography component="div" variant="h3">
          {children}
        </Typography>
      )}
    </Box>
  );
};

export default InfoCard;
