import { useEffect, useMemo, useState } from 'react';
import API from './api';
import { ApiBody, ApiEndpoint, ApiParams, ApiResponse } from './types';

export type ReturnType<T> = {
  isLoading: boolean;
  response?: T;
  error: Error | null;
};

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
}

export type ApiRequestParams<T extends ApiEndpoint> = {
  endpoint: T;
  params?: ApiParams<T>;
  skipRequest?: boolean;
  withCredentials?: boolean;
  baseURL?: string;
  httpMethod?: HTTPMethod;
  body?: ApiBody<T>;
};

export const useApiRequest = <T extends ApiEndpoint = ApiEndpoint>({
  endpoint,
  params,
  skipRequest,
  withCredentials,
  baseURL,
  httpMethod = HTTPMethod.GET,
  body = {} as ApiBody<T>,
}: ApiRequestParams<T>): ReturnType<ApiResponse<T>> => {
  const [response, setResponse] = useState<ApiResponse<T>>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    (async () => {
      if (skipRequest) {
        return;
      }
      setIsLoading(true);
      let res;
      try {
        switch (httpMethod) {
          case HTTPMethod.POST:
            res = await API.post<ApiResponse<T>>(endpoint, body, {
              params,
              withCredentials,
              baseURL,
            });
            break;
          case HTTPMethod.GET:
          default:
            res = await API.get<ApiResponse<T>>(endpoint, {
              params,
              withCredentials,
              baseURL,
            });
            break;
        }
      } catch (err) {
        setError(err as Error);
      }
      setIsLoading(false);
      if (res) {
        setResponse(res.data);
      }
    })();
  }, [params, skipRequest]);

  return useMemo(
    () => ({
      response,
      isLoading,
      error,
    }),
    [response, isLoading, error]
  );
};
