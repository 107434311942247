import React, { FC } from 'react';
import Icon, { IconNames } from '@components/icon';
import { Alert, Typography } from '@mui/material';
import { usePublicConfig } from '@components/public-config/public-config';
import { useLocation } from 'react-router-dom';

export const BannerManager: FC = () => {
  const { publicConfig } = usePublicConfig();
  const location = useLocation();

  return (
    <>
      {publicConfig.banners
        ?.filter(
          (banner) =>
            banner.enabled &&
            (!banner.relevantPath || location.pathname.startsWith(banner.relevantPath))
        )
        .map((banner, index) => (
          <Alert
            key={index}
            sx={{
              alignItems: 'center',
            }}
            variant={banner.variant}
            severity={banner.severity ?? 'info'}
            icon={<Icon icon={(banner.icon as IconNames) ?? 'info'} />}
          >
            <Typography>{banner.message}</Typography>
          </Alert>
        )) ?? null}
    </>
  );
};
