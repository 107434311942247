import Wrapper from '@components/wrapper';
import { Box, Button, Chip, Stack, Switch, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { PublicConfigData, usePublicConfig } from '@components/public-config/public-config';
import { useSnackbar } from 'notistack';
import ReactJson from '@microlink/react-json-view';
import { useThemeMode } from '@components/theme-provider';
import { MetricsCards } from '@pages/landing/metrics-cards';
import { ManualMetricsForm } from '@pages/internal-tool/manual-metrics-form';
import { useApiRequest } from '@api/use-api-request';
import { ApiEndpoint } from '@api/types';
import { capitalizeFirstLetter } from '@utils/formatters';

export const MetricsConfigurationPage = () => {
  const themeMode = useThemeMode();
  const { enqueueSnackbar } = useSnackbar();
  const [updating, setUpdating] = useState(false);
  const { publicConfig, updatePublicConfig } = usePublicConfig();
  const [updatedMetricsConfig, setUpdatedMetricsConfig] = useState(
    publicConfig.metrics?.config ?? {}
  );

  const { response: metricsData } = useApiRequest({
    endpoint: ApiEndpoint.EdgeMetrics,
  });

  useEffect(() => {
    setUpdatedMetricsConfig(publicConfig.metrics?.config ?? {});
  }, [publicConfig.metrics]);

  const handleUpdatePublicConfig = useCallback(async (config: Partial<PublicConfigData>) => {
    try {
      setUpdating(true);
      await updatePublicConfig(config);
      enqueueSnackbar('Public configuration saved successfully', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error saving public configuration', { variant: 'error' });
    }
    setUpdating(false);
  }, []);

  const handleMetricsConfigSave = useCallback(() => {
    void handleUpdatePublicConfig({
      metrics: { ...publicConfig.metrics, config: updatedMetricsConfig },
    });
  }, [publicConfig.metrics, updatedMetricsConfig]);

  return (
    <Wrapper fullWidth={true}>
      <Box
        px={3}
        pt={5}
        pb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        position="relative"
      >
        <Typography variant="h1">Metrics</Typography>
        <Box
          width="60%"
          border="1px solid"
          borderColor="border.default"
          position="absolute"
          top={24}
          right={24}
        >
          <MetricsCards />
          <Chip
            label="Preview"
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              zIndex: 1,
            }}
          />
        </Box>
      </Box>
      <Stack px={3} py={3} spacing={3}>
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Switch
            size="medium"
            checked={publicConfig.metrics?.enabled}
            disabled={updating}
            onChange={() =>
              handleUpdatePublicConfig({
                metrics: { ...publicConfig.metrics, enabled: !publicConfig.metrics?.enabled },
              })
            }
          />
          <Typography>Enable metrics</Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Switch
            size="medium"
            checked={publicConfig.metrics?.tvsIncludesRisk}
            disabled={updating}
            onChange={() =>
              handleUpdatePublicConfig({
                metrics: {
                  ...publicConfig.metrics,
                  tvsIncludesRisk: !publicConfig.metrics?.tvsIncludesRisk,
                },
              })
            }
          />
          <Typography>TVS includes Risk</Typography>
        </Box>
        {publicConfig.metrics?.tvsIncludesRisk && (
          <Box display="flex" flexDirection="row" gap={1} alignItems="center" pl={4}>
            {metricsData
              ?.find((m) => m.product === 'risk')
              ?.tvls?.map((tvls) => (
                <Box
                  key={tvls.protocol}
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                >
                  <Switch
                    size="medium"
                    checked={publicConfig.metrics?.riskTVLs?.includes(tvls.protocol)}
                    disabled={updating}
                    onChange={() =>
                      handleUpdatePublicConfig({
                        metrics: {
                          ...publicConfig.metrics,
                          riskTVLs: publicConfig.metrics?.riskTVLs?.includes(tvls.protocol)
                            ? publicConfig.metrics?.riskTVLs?.filter((t) => t !== tvls.protocol)
                            : [...(publicConfig.metrics?.riskTVLs ?? []), tvls.protocol],
                        },
                      })
                    }
                  />
                  <Typography>{capitalizeFirstLetter(tvls.protocol)}</Typography>
                </Box>
              )) ?? null}
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          py={publicConfig.metrics?.useManualValues ? 1 : undefined}
          alignItems="center"
          borderTop={publicConfig.metrics?.useManualValues ? '1px solid' : 'none'}
          borderColor="divider"
        >
          <Switch
            size="medium"
            checked={publicConfig.metrics?.useManualValues}
            disabled={updating}
            onChange={() =>
              handleUpdatePublicConfig({
                metrics: {
                  ...publicConfig.metrics,
                  useManualValues: !publicConfig.metrics?.useManualValues,
                },
              })
            }
          />
          <Typography>Use manual values</Typography>
        </Box>
        {publicConfig.metrics?.useManualValues && (
          <ManualMetricsForm
            value={publicConfig.metrics.manualValues}
            onSubmit={(updatedMetrics) =>
              handleUpdatePublicConfig({
                metrics: {
                  ...publicConfig.metrics,
                  manualValues: updatedMetrics,
                },
              })
            }
          />
        )}

        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          width="60%"
          py={1}
          borderTop="1px solid"
          borderColor="divider"
        >
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Typography variant="label">Configurations</Typography>
            <Button onClick={handleMetricsConfigSave}>Update</Button>
          </Box>
          <ReactJson
            name={null}
            src={updatedMetricsConfig}
            style={{
              width: '100%',
            }}
            theme={themeMode.mode === 'light' ? 'shapeshifter:inverted' : 'harmonic'}
            displayDataTypes={false}
            enableClipboard={true}
            displayObjectSize={false}
            onAdd={(a) => setUpdatedMetricsConfig(a.updated_src)}
            onEdit={(a) => setUpdatedMetricsConfig(a.updated_src)}
          />
        </Box>
      </Stack>
    </Wrapper>
  );
};
