import Icon from '@components/icon';
import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  darken,
  Pagination,
  PaginationItem,
  Skeleton,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import TableRowData from './table-row';

function descendingComparator<T>(a: T, b: T, sortBy: keyof T) {
  if (b[sortBy] < a[sortBy]) {
    return -1;
  }
  if (b[sortBy] > a[sortBy]) {
    return 1;
  }
  return 0;
}

function getComparator<T>(order: Order, sortBy: keyof T): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, sortBy)
    : (a, b) => -descendingComparator(a, b, sortBy);
}

type Order = 'asc' | 'desc';

export interface Column<TRow> {
  sortBy?: keyof TRow;
  label?: string;
  width?: number;
}

export interface WrappedRowContent {
  rowReactNode: React.ReactNode;
  rowBorderColor: string;
}

export type TableRef = { resetPagination: () => void };

interface TableProps<TRow, TColumn> {
  rows: TRow[];
  columns: TColumn[];
  rowKey: keyof TRow;
  defaultSortOrder?: Order;
  defaultSortBy?: keyof TRow;
  rowContent: (row: TRow, index: number) => React.ReactNode;
  wrappedRowContent?: (row: TRow, id: number) => WrappedRowContent | undefined;
  fixedLayout?: boolean;
  onRowClick?: (data: TRow) => void;
  title?: string | React.ReactNode;
  rowsPerPage?: number;
  loading?: boolean;
  actions?: React.ReactNode;
  sx?: BoxProps['sx'];
  titleProps?: TypographyProps;
  expandableContent?: (row: TRow) => React.ReactNode;
  nonInteractiveTable?: boolean;
  onTitleCheckboxChange?: (isChecked: boolean) => void;
  disabledRowsIds?: number[];
}

export const StyledHeadRow = styled(TableRow)(({ theme }) => ({
  [`& th.${tableCellClasses.head}`]: {
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.bg.paper,
    padding: '8px',
    verticalAlign: 'top',
    [`&:first-of-type`]: {
      paddingLeft: '24px',
    },
    [`&:last-child`]: {
      paddingRight: '24px',
    },
    [`& svg path`]: {
      fill: 'currentColor',
    },
  },
}));

class StyledTableRowProps {
  isDisabledRow?: boolean;
}

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>(
  ({ theme, isDisabledRow = false }) => ({
    [`:hover`]: {
      backgroundColor: theme.palette.bg.paper,
    },
    [`& td.${tableCellClasses.body}`]: {
      borderColor: theme.palette.divider,
      color: isDisabledRow ? theme.palette.text.disabled : theme.palette.text.primary,
      padding: '12px 8px',
      lineHeight: '24px',
      [`&:first-of-type`]: {
        paddingLeft: '24px',
      },
      [`&:last-child`]: {
        paddingRight: '24px',
      },
    },
  })
);
const StyledPagination = styled(Pagination)(({ theme }) => {
  const color =
    theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.dark;
  return {
    width: '100%',
    display: 'block',
    color: color,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: '12px 22px',
    ['.MuiPaginationItem-page']: {
      fontFamily: 'Aeonik Fono',
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.14px',
      color: color,
      justifyContent: 'center',
      padding: '4px',
      margin: 0,
      textAlign: 'center',
      background: 'transparent',
      borderColor: theme.palette.divider,

      '&:hover': {
        backgroundColor: theme.palette.bg.paperHover,
      },

      '&:active': {
        backgroundColor: theme.palette.divider,
      },

      ['&.Mui-selected']: {
        background: color,
        borderColor: color,
        color: theme.palette.getContrastText(color),

        '&:hover': {
          background: darken(color, 0.1),
          borderColor: darken(color, 0.1),
        },

        '&:active': {
          background: darken(color, 0.2),
          borderColor: darken(color, 0.2),
        },
      },
    },
    ['.MuiPaginationItem-previousNext']: {
      margin: 0,
      padding: 0,
      color: color,
    },
    ['.MuiPagination-ul']: {
      ['li']: {
        padding: '0 2px',
      },
    },
    ['.MuiPaginationItem-ellipsis']: {
      color: color,
      fontSize: 14,
      lineHeight: '32px',
      letterSpacing: '0.14px',
      width: 32,
      margin: 0,
      padding: 0,
    },
    ['.MuiTouchRipple-root']: {
      display: 'none',
    },
  };
});

const DEFAULT_ROWS = 8;

const Table = <TRow extends Record<string, unknown>, TColumn extends Column<TRow> = Column<TRow>>(
  {
    rows,
    columns,
    rowKey,
    defaultSortOrder = 'desc',
    defaultSortBy,
    rowContent,
    wrappedRowContent,
    onRowClick,
    title,
    rowsPerPage = DEFAULT_ROWS,
    loading,
    actions,
    sx,
    titleProps,
    expandableContent,
    nonInteractiveTable,
    onTitleCheckboxChange,
    disabledRowsIds,
  }: TableProps<TRow, TColumn>,
  ref: React.Ref<TableRef>
) => {
  const [order, setOrder] = useState<Order>(defaultSortOrder);
  const [sortBy, setSortBy] = useState<keyof TRow | undefined>(defaultSortBy);
  const [page, setPage] = useState(1);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (property: keyof TRow) => {
    const isAsc = sortBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const data = useMemo(() => {
    const result = [...rows];
    if (sortBy) {
      result.sort(getComparator(order, sortBy));
    }
    return result.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
  }, [order, sortBy, page, rowsPerPage, rows, defaultSortOrder, defaultSortBy]);

  const renderRow = useCallback(
    (row: TRow, id: number) => {
      return (
        <TableRowData
          row={row}
          id={id}
          rowKey={rowKey}
          rowContent={rowContent}
          wrappedRowContent={wrappedRowContent}
          onRowClick={onRowClick}
          expandableContent={expandableContent}
          nonInteractiveTable={nonInteractiveTable}
          numberOfColumns={
            columns.length + (onTitleCheckboxChange ? 1 : 0) + (expandableContent ? 1 : 0)
          }
          disabledRow={disabledRowsIds?.includes(id)}
        />
      );
    },
    [rowContent, rowKey, onRowClick]
  );

  useImperativeHandle(ref, () => ({
    resetPagination: () => setPage(1),
  }));

  return (
    <Box borderTop="1px solid" borderColor="divider" sx={sx}>
      <Box
        display="flex"
        sx={{
          bgcolor: 'bg.paper',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          justifyContent: {
            md: 'space-between',
          },
          alignItems: {
            xs: 'stretch',
            md: 'center',
          },
          pt: {
            xs: 2,
            md: 1,
          },
          pb: 1,
        }}
        px={3}
      >
        {title && (
          <Typography variant="h3" sx={titleProps}>
            {title}
          </Typography>
        )}
        <Box
          display="flex"
          columnGap={2}
          sx={{
            padding: {
              md: '4px 0',
            },
            marginTop: {
              xs: 2,
              md: 0,
            },
          }}
        >
          {actions}
        </Box>
      </Box>
      <Box sx={{ overflowX: 'auto' }}>
        <TableContainer>
          <MUITable>
            <TableHead>
              <StyledHeadRow>
                {expandableContent ? <TableCell /> : null}
                {onTitleCheckboxChange ? (
                  <TableCell>
                    <Checkbox
                      onChange={(event) => onTitleCheckboxChange(event.target.checked)}
                    ></Checkbox>
                  </TableCell>
                ) : null}
                {columns.map((headCell, idx) => (
                  <TableCell
                    key={headCell.sortBy?.toString() || `cell-${idx}`}
                    align="left"
                    width={headCell.width}
                    sortDirection={sortBy === headCell.sortBy ? order : false}
                    onClick={() =>
                      headCell.sortBy ? handleRequestSort(headCell.sortBy) : undefined
                    }
                  >
                    <Typography
                      variant="label"
                      display="inline-flex"
                      style={{
                        cursor: 'pointer',
                        verticalAlign: 'top',
                        alignItems: 'center',
                        minHeight: '24px',
                      }}
                    >
                      {headCell.label}
                      {headCell.sortBy ? (
                        <Box display="flex" flexShrink={0}>
                          <Icon icon={sortBy === headCell.sortBy ? `sort-${order}` : 'sort-none'} />
                        </Box>
                      ) : null}
                    </Typography>
                  </TableCell>
                ))}
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              {!loading
                ? data.map(renderRow)
                : [...Array(rowsPerPage)].map((row, rowIdx) => (
                    <StyledTableRow key={rowIdx}>
                      {columns.map((col, cellIdx) => (
                        <TableCell key={cellIdx}>
                          <Skeleton />
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        {rows.length > rowsPerPage && (
          <StyledPagination
            count={rows.length < rowsPerPage ? 1 : Math.ceil(rows.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                slots={{
                  previous: () => (
                    <Button
                      component="span"
                      variant="outlined"
                      startIcon={<Icon icon="chevron-left" />}
                      color="inherit"
                      sx={{ padding: '3px 7px 3px 0' }}
                    >
                      Prev
                    </Button>
                  ),
                  next: () => (
                    <Button
                      component="span"
                      variant="outlined"
                      endIcon={<Icon icon="chevron-right" />}
                      color="inherit"
                      sx={{ padding: '3px 0 3px 7px' }}
                    >
                      Next
                    </Button>
                  ),
                }}
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
};

export default forwardRef(Table) as <TRow, TColumn extends Column<TRow> = Column<TRow>>(
  props: TableProps<TRow, TColumn> & { ref?: React.Ref<{ resetPagination: () => void }> }
) => ReturnType<typeof Table>;
