import { Metrics } from '@components/public-config/public-config';
import React, { FC, FormEvent, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import Grid from '@mui/system/Grid';

interface ManualMetricsFormProps {
  value?: Metrics;
  onSubmit: (metrics: Metrics) => void;
}

export const ManualMetricsForm: FC<ManualMetricsFormProps> = ({ value, onSubmit }) => {
  const [metrics, setMetrics] = useState<Metrics | undefined>(value);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!metrics) return;
    onSubmit(metrics);
  };

  const metricFields: {
    title: string;
    size: number;
    field: keyof Metrics;
  }[] = [
    { title: 'Total Value Secured', size: 4, field: 'totalValueSecured' },
    { title: 'Total Volume Secured', size: 4, field: 'totalVolumeSecured' },
    { title: 'Number of Blockchains', size: 4, field: 'blockchains' },
    { title: 'Number Of Protocols Secured', size: 6, field: 'protocols' },
    { title: 'Number Of Assets Secured', size: 6, field: 'assets' },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap={1} width="60%">
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Typography variant="label">Manual values</Typography>
          <Button type="submit">Update</Button>
        </Box>
        <Grid
          container
          sx={{ px: 2, width: '100%', height: '100%' }}
          border="1px solid"
          borderColor="divider"
        >
          {metricFields.map((m) => (
            <Grid size={m.size} p={2} key={m.title}>
              <TextField
                value={metrics?.[m.field]}
                type="number"
                variant="outlined"
                label={m.title}
                onChange={(e) =>
                  setMetrics({
                    ...metrics,
                    [m.field]: e.target.value ? parseInt(e.target.value) : undefined,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </form>
  );
};
