import Icon from '@components/icon';
import React, { useState } from 'react';
import {
  alpha,
  Box,
  CSSObject,
  Drawer as MuiDrawer,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Tab } from '@components/router-tabs';
import { motion } from 'motion/react';

const navIconStyles = {
  height: 24,
  width: 24,
};

const drawerWidth = 180;
const drawerWidthClosed = 80;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerWidthClosed,
  [theme.breakpoints.up('sm')]: {
    width: drawerWidthClosed,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  width: drawerWidthClosed,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  borderRight: '1px solid',
  borderColor: theme.palette.divider,
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.default,
  },
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));

function NavItem(props: {
  item: Tab;
  onClick?: () => void;
  hideBorder?: boolean;
  expanded?: boolean;
  isActive?: boolean;
}) {
  const theme = useTheme();

  const { item, hideBorder, expanded, isActive } = props;

  const activeColor =
    theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.dark;

  return (
    <Tooltip
      title={
        expanded || !item.label ? undefined : <Typography variant="body1">{item.label}</Typography>
      }
      placement="right"
    >
      <Box
        width="100%"
        component="div"
        aria-label={typeof item.label === 'string' ? item.label : undefined}
        sx={{
          padding: 1.5,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderLeft: hideBorder ? 'none' : '2px solid',
          borderColor: isActive && !hideBorder ? activeColor : 'transparent',
          background:
            isActive && !hideBorder
              ? `linear-gradient(90deg, ${alpha(activeColor, 0.64)}, ${alpha(activeColor, 0.25)}, transparent);`
              : 'transparent',
          color: isActive && !hideBorder ? theme.palette.text.primary : theme.palette.icon.default,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={drawerWidthClosed - 3 * 8 /* spacing unit */ - (isActive ? 2 : 0)}
        >
          {item.icon}
        </Box>
        <Box flex={1} overflow="hidden">
          {expanded && <Typography variant="h4">{item.label}</Typography>}
        </Box>
      </Box>
    </Tooltip>
  );
}

function LeftNavItem(props: {
  item: Tab;
  iconColor: string;
  hideBorder?: boolean;
  expanded?: boolean;
}) {
  const { item, iconColor, hideBorder, expanded } = props;

  return (
    <NavLink
      to={item.to ?? ''}
      aria-label={typeof item.label === 'string' ? item.label : undefined}
      style={{
        textDecoration: 'none',
        color: iconColor,
      }}
    >
      {({ isActive }) => (
        <NavItem item={item} hideBorder={hideBorder} expanded={expanded} isActive={isActive} />
      )}
    </NavLink>
  );
}

export const LeftNavContent = ({
  leftNavItems,
  headerIsHidden,
}: {
  leftNavItems: Tab[];
  headerIsHidden?: boolean;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const iconColor = theme.palette.icon.default;

  return (
    <>
      <Drawer
        variant="permanent"
        open={drawerOpen}
        PaperProps={{
          sx: {
            zIndex: 0,
          },
        }}
      >
        <Box
          flexDirection="column"
          height="100%"
          display="flex"
          justifyContent="space-between"
          color={iconColor}
          minWidth="80px"
        >
          <Box
            component={motion.div}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            initial={{ height: 80 }}
            animate={headerIsHidden ? { height: 0 } : { height: 80 }}
            maxHeight={80}
            minWidth={80}
          ></Box>
          <Stack direction="column" spacing={1} py={1}>
            {leftNavItems.map((item) => (
              <LeftNavItem
                key={item.to}
                item={item}
                iconColor={iconColor}
                expanded={drawerOpen}
                hideBorder={item.to === '#'}
              />
            ))}
          </Stack>
          <Box flex={1} />
          <Stack direction="column" spacing={1} pb={2} alignItems="center">
            <IconButton
              onClick={() => setDrawerOpen((prev) => !prev)}
              sx={{
                color: iconColor,
                border: '1px solid',
                borderColor: iconColor,
              }}
              size="small"
            >
              <Icon icon={drawerOpen ? 'drawer-close' : 'drawer-open'} style={navIconStyles} />
            </IconButton>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
