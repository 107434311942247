import Header from '@components/header';
import PageLoader from '@components/page-loader';
import RouterTabs, { Tab } from '@components/router-tabs';
import Wrapper from '@components/wrapper';
import { Box, Grid2 as Grid } from '@mui/material';
import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../footer';
import { BannerManager } from '@components/system-banner/banner-manager';
import { LeftNavContent } from '@components/layout-header-footer/left-nav';

interface MainLayoutProps {
  tabs: Tab[];
  leftNavTabs?: Tab[];
  fullWidth?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({ tabs, leftNavTabs, fullWidth }) => {
  const { pathname } = useLocation();

  const showTabs = tabs.filter((tab) => !tab.disabled).some((tab) => pathname?.startsWith(tab.to!));
  const [stickyNavBar, setStickyNavBar] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setStickyNavBar(true);
      } else {
        setStickyNavBar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Header fullWidth={fullWidth} />

      <Grid container spacing={0} columns={12} minHeight="calc(100% - 191px)">
        {leftNavTabs && (
          <Grid size="auto">
            <LeftNavContent leftNavItems={leftNavTabs} headerIsHidden={stickyNavBar} />
          </Grid>
        )}
        <Grid size="grow" p={0}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              minHeight: 'calc(100%)',
            }}
          >
            <Box flex="0" position="sticky" left="0" top="0" zIndex="appBar">
              <BannerManager />
              {showTabs && (
                <Box
                  sx={{
                    bgcolor: stickyNavBar ? 'background.paper' : 'background.default',
                  }}
                >
                  <Wrapper fullWidth={fullWidth}>
                    <RouterTabs data={tabs} />
                  </Wrapper>
                </Box>
              )}
            </Box>

            <Box display="flex" flex="1 0 0">
              <Suspense fallback={<PageLoader />}>
                <Outlet />
              </Suspense>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default MainLayout;
