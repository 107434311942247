import { AlertColor } from '@mui/material';
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import API, { API_WITH_CREDENTIALS } from '@api/api';

export type BannerData = {
  enabled: boolean;
  severity: AlertColor;
  variant?: 'outlined' | 'filled';
  icon?: string;
  message: string;
  relevantPath?: string;
};

export type Metrics = {
  totalValueSecured?: number;
  totalVolumeSecured?: number;
  blockchains?: number;
  protocols?: number;
  assets?: number;
};

export type MetricsData = {
  enabled?: boolean;
  useManualValues?: boolean;
  tvsIncludesRisk?: boolean;
  includesBorrowedTVL?: boolean;
  manualValues?: Metrics;
  riskTVLs?: string[];
  config?: object;
};

export interface PublicConfigData {
  banners?: BannerData[];
  metrics?: MetricsData;
}

interface PublicConfigContextData {
  publicConfig: PublicConfigData;
  updatePublicConfig: (config: Partial<PublicConfigData>) => Promise<void>;
}

const PublicConfigContext = createContext<PublicConfigContextData>({
  publicConfig: {},
  updatePublicConfig: () => Promise.resolve(),
});

export const usePublicConfig = () => useContext<PublicConfigContextData>(PublicConfigContext);

interface PublicConfigProviderProps {
  children: ReactNode;
}

export const PublicConfigProvider: FC<PublicConfigProviderProps> = ({ children }) => {
  const [publicConfig, setPublicConfig] = useState<PublicConfigData>({});

  useEffect(() => {
    const fetchPublicConfig = async () => {
      try {
        const res = await API.get<PublicConfigData>('/edge/public_config');
        setPublicConfig(res.data ?? {});
      } catch (e) {
        setPublicConfig({});
      }
    };

    void fetchPublicConfig();
  }, []);

  const publicConfigValue = useMemo(() => {
    return {
      publicConfig,
      updatePublicConfig: async (config: Partial<PublicConfigData>) => {
        const res = await API_WITH_CREDENTIALS.post<PublicConfigData>(
          '/edge/public_config',
          config
        );
        setPublicConfig(res.data ?? {});
      },
    };
  }, [publicConfig]);

  return (
    <PublicConfigContext.Provider value={publicConfigValue}>
      {children}
    </PublicConfigContext.Provider>
  );
};
