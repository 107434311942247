import { EdgeMetricsResponse } from '@api/types';
import { PublicConfigData } from '@components/public-config/public-config';

export function calculateTotalValueSecured(
  metricsData?: EdgeMetricsResponse[],
  publicConfig?: PublicConfigData
) {
  if (
    publicConfig?.metrics?.useManualValues &&
    publicConfig?.metrics.manualValues?.totalValueSecured !== undefined
  ) {
    return publicConfig.metrics.manualValues?.totalValueSecured ?? 0;
  }

  const includesBorrowedTVL = publicConfig?.metrics?.includesBorrowedTVL ?? false;

  const riskTvs =
    metricsData
      ?.find((m) => m.product === 'risk')
      ?.tvls?.reduce((acc, tvlData) => {
        if (!publicConfig?.metrics?.riskTVLs?.includes(tvlData.protocol)) {
          return acc;
        }
        return acc + tvlData.tvl;
      }, 0) ?? 0;

  const priceTvs =
    metricsData
      ?.find((m) => m.product === 'price')
      ?.tvls?.reduce((acc, tvlData) => {
        if (tvlData.edgeChains?.length !== 0) {
          let tvs = acc;

          for (const chain of tvlData.edgeChains) {
            if (chain.tokens?.length !== 0) {
              for (const tokenTvl of Object.values(chain.tokens)) {
                tvs += tokenTvl;
              }
            } else {
              tvs += chain.tvl + (includesBorrowedTVL ? chain.tvlBorrowed : 0);
            }
          }

          return tvs;
        } else if (tvlData.allChains?.length !== 0) {
          let tvs = acc;
          for (const chain of tvlData.allChains) {
            tvs += chain.tvl + (includesBorrowedTVL ? chain.tvlBorrowed : 0);
          }

          return tvs;
        }

        return tvlData.tvl + (includesBorrowedTVL ? tvlData.tvlBorrowed : 0);
      }, 0) ?? 0;

  return publicConfig?.metrics?.tvsIncludesRisk ? riskTvs + priceTvs : priceTvs;
}

export function calculateTotalVolumeSecured(
  metricsData?: EdgeMetricsResponse[],
  publicConfig?: PublicConfigData
) {
  if (
    publicConfig?.metrics?.useManualValues &&
    publicConfig?.metrics.manualValues?.totalVolumeSecured !== undefined
  ) {
    return publicConfig.metrics.manualValues?.totalVolumeSecured ?? 0;
  }

  return (
    metricsData?.reduce(
      (acc, protocol) => acc + (protocol.derivatives?.reduce((sum, d) => sum + d.volume, 0) ?? 0),
      0
    ) ?? 0
  );
}

export function calculateTotalBlockchains(
  metricsData?: EdgeMetricsResponse[],
  pushNetworks?: string[],
  publicConfig?: PublicConfigData
) {
  if (
    publicConfig?.metrics?.useManualValues &&
    publicConfig?.metrics.manualValues?.blockchains !== undefined
  ) {
    return publicConfig.metrics.manualValues?.blockchains ?? 0;
  }

  return new Set([
    ...(metricsData?.map((m) => m.chains ?? []).flat() ?? []),
    ...(pushNetworks?.map((ch) => ch.toLowerCase()) ?? []),
  ]).size;
}

export function calculateTotalProtocols(
  metricsData?: EdgeMetricsResponse[],
  publicConfig?: PublicConfigData
) {
  if (
    publicConfig?.metrics?.useManualValues &&
    publicConfig?.metrics.manualValues?.protocols !== undefined
  ) {
    return publicConfig.metrics.manualValues?.protocols ?? 0;
  }

  return new Set(metricsData?.map((m) => m.protocols ?? []).flat() ?? []).size;
}

export function calculateTotalAssets(
  metricsData?: EdgeMetricsResponse[],
  publicConfig?: PublicConfigData
) {
  if (
    publicConfig?.metrics?.useManualValues &&
    publicConfig?.metrics.manualValues?.assets !== undefined
  ) {
    return publicConfig.metrics.manualValues?.assets ?? 0;
  }

  return metricsData?.reduce((acc, m) => acc + (m.totalAssetsSecured ?? 0), 0) ?? 0;
}
