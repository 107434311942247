import { AuthCallbackResult, AuthLoginResult, UserInfo } from '@components/auth/types';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { API_WITH_CREDENTIALS } from '@api/api';

interface UserData {
  roles: string[];
  userInfo: UserInfo;
}

interface AuthContextData {
  isAuthenticated: boolean;
  isRefreshing: boolean;
  authenticate: () => Promise<void>;
  logout: () => void;
  hasRole: (role?: string) => boolean;
  user: UserInfo | null;
}

const AuthContext = createContext<AuthContextData>({
  isAuthenticated: false,
  isRefreshing: true,
  authenticate: () => Promise.resolve(),
  logout: () => {},
  hasRole: () => false,
  user: null,
});

export const useAuth = () => useContext<AuthContextData>(AuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const res = await API_WITH_CREDENTIALS.post<AuthCallbackResult>(`/auth/refresh`, {});
        setUserData({
          roles: res.data.roles,
          userInfo: res.data.user_info,
        });
        setIsAuthenticated(true);
      } catch (e) {
        setIsAuthenticated(false);
      }
      setIsRefreshing(false);
    };

    void checkAuth();
  }, []);

  const authenticate = useCallback(async () => {
    const res = await API_WITH_CREDENTIALS.post<AuthLoginResult>(`/auth/login`, {
      state: `${window.location.origin}/auth/callback?redirect=${window.location.pathname}`,
    });
    window.location.href = res.data.url;
  }, []);

  const logout = useCallback(() => {
    void API_WITH_CREDENTIALS.post<AuthCallbackResult>(`auth/logout`, {});
    setIsAuthenticated(false);
    setUserData(null);
  }, []);

  const hasRole = useCallback(
    (role?: string) => (role ? !!userData?.roles.includes(role) : true),
    [userData]
  );

  const authProviderValue = useMemo<AuthContextData>(
    () => ({
      isAuthenticated,
      authenticate,
      logout,
      user: userData?.userInfo ?? null,
      hasRole,
      isRefreshing,
    }),
    [isAuthenticated, hasRole, authenticate, logout, userData, isRefreshing]
  );

  return <AuthContext.Provider value={authProviderValue}>{children}</AuthContext.Provider>;
};
