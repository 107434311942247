export enum ApiEndpoint {
  Feeds = '/trading-view/feeds',
  FeedsData = '/trading-view/feeds-data',
  Validators = '/trading-view/validators',
  Providers = '/trading-view/reports',
  RiskOracleOverview = '/risk-oracle/api/v1/overview',
  RiskOracleProtocolOverview = '/risk-oracle/api/v1/protocol_overview',
  RiskOracleProtocolRecommendations = '/risk-oracle/api/v1/protocol_recommendations',
  RiskOracleProtocolHistoricalRecommendations = '/risk-oracle/api/v1/historical_recommendations',
  // Internal Tool
  LatestOnChainRecommendations = '/risk-oracle/application/recommendations/latest_on_chain', // POST
  HistoricalOnChainRecommendations = '/risk-oracle/application/recommendations', // POST
  GatedTransactions = '/risk-oracle/application/gated_transaction', // POST
  GatedTransactionsAction = '/risk-oracle/application/gated_transaction/action', // POST
  GetAssets = '/risk-oracle/application/risk_configuration/asset', // POST
  UpdateAssets = '/risk-oracle/application/risk_configuration/asset/update', // POST
  EvaluationSupportingData = '/risk-oracle/application/evaluation', // POST
  // Metrics
  EdgeMetrics = '/edge/metrics', // GET
}

export enum WsEndpoint {
  Feeds = 'stream-feeds',
  Validators = 'stream-validators',
  Providers = 'stream-reports',
}

export type FeedPrice = {
  interval: '1h' | '24h' | '7d';
  diff: number;
};

export type Feed = {
  feed: string;
  display_name?: string;
  type: 'pull' | 'push';
  providers: string[];
  last_price: number;
  market_cap: number;
  circulating_supply: number;
  usd_24h_vol: number;
  prices: FeedPrice[];
  network?: string;
  address?: string;
  deviation_threshold?: number;
  nextTimestamp?: number;
};

export type FeedsResponse = {
  feeds: Feed[];
};

export type Granularity = '1' | '1h' | '24h';
export type TimeRange = '1h' | '24h' | '7d' | '30d';
export type Price = {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  avg: number;
};

export type FeedsDataResponse = {
  queryType: Granularity;
  interval: TimeRange;
  result: Price[];
  min: number;
  max: number;
  average: number;
  diff: number;
  last_price: number;
};

export type FeedsDataParams = {
  interval: TimeRange;
  type: Granularity;
  feed: string;
};

export type RiskOracleProtocolParams = {
  protocol: string;
};

export type RiskOracleProtocolHistoricalRecommendationsParams = {
  protocol: string;
  assetName: string;
  market: string;
  chain: string;
  poolName?: string;
  parameterName: string;
};

export type FeedParam = {
  feed: string;
};

export type FeedsParam = {
  feeds: string;
};

export type Validator = {
  name: string;
  price: number;
  timestamp: number;
  failed_rounds: number;
  success_rate: number;
};

export type ValidatorsResponse = {
  validators: { validators: Validator[] }[];
};

export type Provider = {
  failed_counts: number;
  feedId: string;
  price: number;
  provider: string;
  success_rate: number;
  ts: number;
};

export type ProvidersResponse = {
  reports: Provider[];
};

export type RiskOracleOverviewResponse = {
  total_value_secured: number;
  total_assets_secured: number;
  total_blockchains: number;
  risk_oracle_uptime: number;
  total_risk_oracle_updates: number;
  supported_protocols: string[];
  total_risk_parameters: number;
};

export type RiskOracleProtocolOverviewResponse = {
  total_value_secured: number;
  total_assets_secured: number;
  total_blockchains: number;
  risk_oracle_uptime: number;
};

export interface RecommendationHistoricalChart {
  timestamp: number;
  value: number;
  tx_hash: string;
}

export type RiskOracleProtocolRecommendation = {
  risk_parameter_query_param_name: string;
  risk_parameter_name: string;
  market: {
    chain: string;
    market: string;
    pool_name?: string;
  };
  asset: string;
  recommended_value: number;
  recommended_value_type: string;
  tx_hash: string;
  last_on_chain_update: number;
};

export enum RecommendationType {
  'decimal' = 'decimal',
  'currency' = 'currency',
  'percent' = 'percent',
  'bps' = 'bps',
  'integer' = 'integer',
  'string' = 'string',
  'timestamp' = 'timestamp',
  'address' = 'address',
}

export enum SignerAction {
  Approve = 'approve',
  Reject = 'reject',
}

export enum RecommendationsStatus {
  NoNeedToOnChainPublish = 'NoNeedToOnChainPublish',
  QueuedForOnChainPublishing = 'QueuedForOnChainPublishing',
  SentForOnChainPublishing = 'SentForOnChainPublishing',
  OnChainPublished = 'OnChainPublished',
  FailedToOnChainPublish = 'FailedToOnChainPublish',
}

export interface GatedTransactionSigner {
  email: string;
  timestamp: number;
  action: SignerAction;
  reason: string;
}

export interface RecommendationsMarketResponse {
  chain: string;
  market_id: string;
  market_name: string;
  pool_name?: string;
}

export interface RecommendationResponse {
  parameter_name: string;
  parameter_display_name: string;
  market: RecommendationsMarketResponse;
  asset_name: string;
  client: string;
  current_value: number;
  recommended_value: number;
  value_type: RecommendationType;
  evaluation_timestamp: number;
  signers: GatedTransactionSigner[];
  status: RecommendationsStatus;
  tx_hash?: string;
  tx_timestamp?: number;
  tx_destination_chain?: string;
  is_excluded: boolean;
  reference_id: string;
}

export interface OnChainRecommendationFiltersBody {
  filters: {
    client?: string;
    asset_name?: string;
    parameter_name?: string;
    chain?: string;
    market_id?: string;
    pool_name?: string;
    status?: { $in: RecommendationsStatus[] };
  };
  add_signers?: boolean;
  inactive_recommendations?: boolean;
}

export enum GatedTransactionState {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Sent = 'sent',
}

export interface GatedTransactionResponse {
  recommendations: RecommendationResponse[];
  reference_id: string;
  simulation_result_id: string;
  model_name: string;
  client: string;
  state: GatedTransactionState;
  signers: GatedTransactionSigner[];
  min_approvers_count: number;
}

export interface AssetConfiguration {
  is_on_chain_publishing_enabled: boolean;
  is_on_chain_publishing_gated: boolean;
}

export interface AssetResponse {
  asset_name: string;
  client: string;
  configuration: AssetConfiguration;
  parameter_name: string;
  parameter_display_name: string;
  market: RecommendationsMarketResponse;
}

export interface AssetBody {
  asset_name: string;
  client: string;
  chain: string;
  parameter_name: string;
  market_id: string;
  is_on_chain_publishing_enabled: boolean;
  pool_name?: string;
}

export interface SupportingDataParams {
  reference_id: string;
  parameter_name: string;
}

interface EvaluationDrillDownConfig {
  modelName: string;
  modelId: string;
  inputs: object[];
  execution_target: string;
}

export interface SupportingDataResponse {
  reference_id: string;
  parameter_name: string;
  evaluation_timestamp: number;
  recommended_value: number;
  value_type: RecommendationType;
  tx_hash?: string;
  simulation_config: EvaluationDrillDownConfig;
  model_name: string;
  datadog_link: string;
  simulation_result_id: string;
}

export interface ChainTvl {
  chain: string;
  tvl: number;
  tvlBorrowed: number;
  tokens: Record<string, number>;
}

export interface ProtocolTvl {
  protocol: string;
  tvl: number;
  tvlBorrowed: number;
  allChains: ChainTvl[];
  edgeChains: ChainTvl[];
}

export interface ProtocolDerivatives {
  protocol: string;
  volume: number;
}

export interface EdgeMetricsResponse {
  timestamp: Date;
  product: 'risk' | 'price';
  chains: string[];
  chainsByProtocol: Record<string, string[]>;
  assets: {
    chain?: string;
    asset: string;
    market?: string;
    protocol: string;
    type?: 'push' | 'pull';
    product: string;
  }[];
  totalAssetsSecured?: number;
  protocols: string[];
  tvls?: ProtocolTvl[];
  derivatives?: ProtocolDerivatives[];
}

export type RiskOracleProtocolRecommendationResponse = RiskOracleProtocolRecommendation[];
export type RiskOracleProtocolHistoricalRecommendationsResponse = RecommendationHistoricalChart[];

export type ApiResponseMap = {
  [ApiEndpoint.Feeds]: FeedsResponse;
  [ApiEndpoint.FeedsData]: FeedsDataResponse;
  [ApiEndpoint.Validators]: ValidatorsResponse;
  [ApiEndpoint.Providers]: ProvidersResponse;
  [ApiEndpoint.RiskOracleOverview]: RiskOracleOverviewResponse;
  [ApiEndpoint.RiskOracleProtocolOverview]: RiskOracleProtocolOverviewResponse;
  [ApiEndpoint.RiskOracleProtocolRecommendations]: RiskOracleProtocolRecommendationResponse;
  [ApiEndpoint.RiskOracleProtocolHistoricalRecommendations]: RiskOracleProtocolHistoricalRecommendationsResponse;
  [ApiEndpoint.LatestOnChainRecommendations]: RecommendationResponse[];
  [ApiEndpoint.HistoricalOnChainRecommendations]: RecommendationResponse[];
  [ApiEndpoint.GatedTransactions]: GatedTransactionResponse[];
  [ApiEndpoint.GatedTransactionsAction]: undefined;
  [ApiEndpoint.GetAssets]: AssetResponse[];
  [ApiEndpoint.UpdateAssets]: undefined;
  [ApiEndpoint.EvaluationSupportingData]: SupportingDataResponse;
  [ApiEndpoint.EdgeMetrics]: EdgeMetricsResponse[];
};

export type WsDataMap = {
  [WsEndpoint.Feeds]: Feed[];
  [WsEndpoint.Validators]: { validators: Validator[] };
  [WsEndpoint.Providers]: Provider;
};

export type WsParamsMap = {
  [WsEndpoint.Feeds]: FeedParam;
  [WsEndpoint.Validators]: FeedsParam;
  [WsEndpoint.Providers]: FeedParam;
};

export type ApiParamsMap = {
  [ApiEndpoint.Feeds]: FeedParam;
  [ApiEndpoint.FeedsData]: FeedsDataParams;
  [ApiEndpoint.Validators]: FeedsParam;
  [ApiEndpoint.Providers]: FeedParam;
  [ApiEndpoint.RiskOracleOverview]: undefined;
  [ApiEndpoint.RiskOracleProtocolOverview]: RiskOracleProtocolParams;
  [ApiEndpoint.RiskOracleProtocolRecommendations]: RiskOracleProtocolParams;
  [ApiEndpoint.RiskOracleProtocolHistoricalRecommendations]: RiskOracleProtocolHistoricalRecommendationsParams;
  [ApiEndpoint.LatestOnChainRecommendations]: undefined;
  [ApiEndpoint.HistoricalOnChainRecommendations]: undefined;
  [ApiEndpoint.GatedTransactions]: undefined;
  [ApiEndpoint.GatedTransactionsAction]: undefined;
  [ApiEndpoint.GetAssets]: undefined;
  [ApiEndpoint.UpdateAssets]: undefined;
  [ApiEndpoint.EvaluationSupportingData]: SupportingDataParams;
  [ApiEndpoint.EdgeMetrics]: undefined;
};

export type ApiBodyMap = {
  [ApiEndpoint.Feeds]: undefined;
  [ApiEndpoint.FeedsData]: undefined;
  [ApiEndpoint.Validators]: undefined;
  [ApiEndpoint.Providers]: undefined;
  [ApiEndpoint.RiskOracleOverview]: undefined;
  [ApiEndpoint.RiskOracleProtocolOverview]: undefined;
  [ApiEndpoint.RiskOracleProtocolRecommendations]: undefined;
  [ApiEndpoint.RiskOracleProtocolHistoricalRecommendations]: undefined;
  [ApiEndpoint.LatestOnChainRecommendations]: OnChainRecommendationFiltersBody;
  [ApiEndpoint.HistoricalOnChainRecommendations]: OnChainRecommendationFiltersBody;
  [ApiEndpoint.GatedTransactions]: {
    filters: {
      state: GatedTransactionState;
    };
  };
  [ApiEndpoint.GatedTransactionsAction]: {
    actions: { referenceId: string; action: SignerAction }[];
  };
  [ApiEndpoint.GetAssets]: undefined;
  [ApiEndpoint.UpdateAssets]: { actions: AssetBody[] };
  [ApiEndpoint.EvaluationSupportingData]: undefined;
  [ApiEndpoint.EdgeMetrics]: undefined;
};

export type ApiResponse<T extends ApiEndpoint> = ApiResponseMap[T];
export type ApiParams<T extends ApiEndpoint> = ApiParamsMap[T];
export type ApiBody<T extends ApiEndpoint> = ApiBodyMap[T];
export type WsData<T extends WsEndpoint> = WsDataMap[T];
export type WsParams<T extends WsEndpoint> = WsParamsMap[T];
