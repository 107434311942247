import { useAuth } from '@components/auth';
import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EdgeLayout } from './layouts/edge-layout';
import { InternalToolLayout } from './layouts/internal-tool-layout';
import { InternalToolPaths, RouterPaths } from './types/routers';
import { BannersConfigurationPage } from '@pages/internal-tool/banners-configuration';
import { MetricsConfigurationPage } from '@pages/internal-tool/metrics-configutations';

/**
 * Dynamic imports allow the app bundle to be code-split at the route level
 */

const LandingPage = lazy(() => import('@pages/landing'));
const PriceFeedsListPage = lazy(() => import('@pages/price-feeds-list'));
const PriceFeedsDetailsPage = lazy(() => import('@pages/price-feeds-details'));
const RiskFeedsPage = lazy(() => import('@pages/risk-feeds'));
const RiskFeedsDetailsPage = lazy(() => import('@pages/risk-feeds-details'));
const OverviewPage = lazy(() => import('@pages/internal-tool/overview'));
const OverviewParameterDrillDown = lazy(
  () => import('@components/drill-down/parameter-drill-down')
);

const EvaluationParameterDrillDown = lazy(
  () => import('@components/drill-down/evaluation-drill-down')
);
const ApprovalHubPage = lazy(() => import('@pages/internal-tool/approval-hub'));
const RecommendationsSettingsPage = lazy(
  () => import('@pages/internal-tool/recommendation-settings')
);

function App() {
  const auth = useAuth();

  return (
    <Routes>
      <Route path="/">
        <Route element={<EdgeLayout />}>
          <Route index element={<Navigate to={RouterPaths.Landing} replace />} />
          <Route path={RouterPaths.Landing} element={<LandingPage />} />
          <Route path={RouterPaths.PriceFeeds} element={<PriceFeedsListPage />} />
          <Route path={RouterPaths.PriceFeedDetails} element={<PriceFeedsDetailsPage />} />
          <Route path={RouterPaths.RiskFeeds} element={<RiskFeedsPage />} />
          <Route path={RouterPaths.RiskFeedDetails} element={<RiskFeedsDetailsPage />} />
          <Route path="*" element={<Navigate to={RouterPaths.Landing} replace />} />
        </Route>
        {(auth.isRefreshing || auth.hasRole('chaos-labs-member')) && (
          <>
            <Route element={<InternalToolLayout />} path={InternalToolPaths.Overview + '/*'}>
              <Route index element={<Navigate to={InternalToolPaths.OverviewMain} replace />} />
              <Route path={InternalToolPaths.OverviewMain} element={<OverviewPage />} />
              <Route
                path={InternalToolPaths.OverviewParameterDrillDown}
                element={<OverviewParameterDrillDown />}
              />
              <Route
                path={InternalToolPaths.EvaluationParameterDrillDown}
                element={<EvaluationParameterDrillDown />}
              />
              <Route path={InternalToolPaths.ApprovalHub} element={<ApprovalHubPage />} />
              <Route
                path={InternalToolPaths.RecommendationsSettings}
                element={<RecommendationsSettingsPage />}
              />
              <Route path="*" element={<Navigate to={InternalToolPaths.OverviewMain} replace />} />
            </Route>
            <Route
              element={<InternalToolLayout />}
              path={InternalToolPaths.EdgeConfiguration + '/*'}
            >
              <Route path={InternalToolPaths.Banners} element={<BannersConfigurationPage />} />
              <Route path={InternalToolPaths.Metrics} element={<MetricsConfigurationPage />} />
              <Route path="*" element={<Navigate to={InternalToolPaths.Banners} replace />} />
            </Route>
          </>
        )}
      </Route>
    </Routes>
  );
}

export default App;
