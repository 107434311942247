import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, TableCell } from '@mui/material';
import React, { useState } from 'react';
import { StyledTableRow, WrappedRowContent } from '.';

interface TableRowProps<TRow> {
  row: TRow;
  id: number;
  rowKey: keyof TRow;
  rowContent: (row: TRow, index: number) => React.ReactNode;
  onRowClick?: (data: TRow) => void;
  expandableContent?: (row: TRow) => React.ReactNode;
  nonInteractiveTable?: boolean;
  numberOfColumns: number;
  reEnableParameterCallback?: () => void;
  wrappedRowContent?: (row: TRow, id: number) => WrappedRowContent | undefined;
  disabledRow?: boolean;
}

const TableRowData = <TRow,>({
  row,
  id,
  rowKey,
  rowContent,
  wrappedRowContent,
  onRowClick,
  expandableContent,
  nonInteractiveTable,
  numberOfColumns,
  disabledRow,
}: TableRowProps<TRow>): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandedClick = () => {
    setIsExpanded((prev) => !prev);
  };

  const wrappedContent = wrappedRowContent ? wrappedRowContent(row, numberOfColumns) : undefined;

  return (
    <React.Fragment>
      <StyledTableRow
        key={`${row[rowKey]}_${id}`}
        tabIndex={-1}
        onClick={onRowClick ? () => onRowClick(row) : undefined}
        isDisabledRow={disabledRow}
        sx={{
          flex: 1,
          cursor: onRowClick ? 'pointer' : undefined,
          ...(nonInteractiveTable ? { backgroundColor: 'bg.paper' } : {}),
          ...(wrappedContent?.rowBorderColor
            ? { border: `2px solid ${wrappedContent.rowBorderColor}` }
            : {}),
          color: 'blue',
        }}
      >
        {expandableContent ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleExpandedClick}
              sx={{ color: 'text.disabled' }}
            >
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        {rowContent(row, id)}
      </StyledTableRow>
      {wrappedContent?.rowReactNode}
      {isExpanded && expandableContent && (
        <StyledTableRow>
          <TableCell colSpan={12}>
            <Collapse in={true} timeout="auto" unmountOnExit>
              {expandableContent(row)}
            </Collapse>
          </TableCell>
        </StyledTableRow>
      )}
    </React.Fragment>
  );
};

export default TableRowData;
