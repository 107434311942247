import Wrapper from '@components/wrapper';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Checkbox,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import Table from '@components/table';
import {
  BannerData,
  PublicConfigData,
  usePublicConfig,
} from '@components/public-config/public-config';
import Icon, { iconNames, IconNames } from '@components/icon';
import { MenuProps } from '@mui/material/Menu';
import { useSnackbar } from 'notistack';

const menuProps: (t: Theme) => Partial<MenuProps> = (theme: Theme) => ({
  MenuListProps: {
    sx: {
      backgroundColor: theme.palette.bg.paperOpacity,
      border: '1px solid',
      borderColor: theme.palette.divider,
      borderRadius: 1,
      borderTop: 'none',
    },
  },
});

export const BannersConfigurationPage = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [bannerIndexToEdit, setBannerIndexToEdit] = useState<number | null>(null);
  const [drawerBannerData, setDrawerBannerData] = useState<BannerData>({
    enabled: true,
    severity: 'info',
    message: '',
  });
  const { publicConfig, updatePublicConfig } = usePublicConfig();

  const handleUpdatePublicConfig = useCallback(async (config: Partial<PublicConfigData>) => {
    try {
      await updatePublicConfig(config);
      enqueueSnackbar('Public configuration saved successfully', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error saving public configuration', { variant: 'error' });
    }
  }, []);

  const handleAddBanner = () => {
    setBannerIndexToEdit(null);
    setOpen(true);
    setDrawerBannerData({
      enabled: true,
      severity: 'info',
      message: '',
    });
  };

  const handleEditBanner = (index: number) => {
    setBannerIndexToEdit(index);
    setDrawerBannerData(publicConfig.banners![index]);
    setOpen(true);
  };

  const handleSaveBanner = useCallback(() => {
    if (bannerIndexToEdit === null) {
      void handleUpdatePublicConfig({
        banners: [...(publicConfig.banners ?? []), drawerBannerData],
      });
    } else {
      void handleUpdatePublicConfig({
        banners: publicConfig.banners?.map((banner, i) =>
          i === bannerIndexToEdit ? drawerBannerData : banner
        ),
      });
    }
    setOpen(false);
  }, [bannerIndexToEdit, drawerBannerData, publicConfig.banners, updatePublicConfig]);

  const handleDeleteBanner = (index: number) => {
    void handleUpdatePublicConfig({
      banners: publicConfig.banners?.filter((_, i) => i !== index),
    });
  };

  const handleCheckboxClick = (index: number) => {
    void handleUpdatePublicConfig({
      banners: publicConfig.banners?.map((banner, i) =>
        i === index ? { ...banner, enabled: !banner.enabled } : banner
      ),
    });
  };

  const handleCopyBanner = (index: number) => {
    const banner = publicConfig.banners![index];
    setDrawerBannerData({
      ...banner,
      enabled: false,
    });
    setBannerIndexToEdit(null);
    setOpen(true);
  };

  return (
    <Wrapper fullWidth={true}>
      <Box
        px={3}
        pt={5}
        pb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography variant="h1">Banners</Typography>
        <Button onClick={handleAddBanner}>Add Banner</Button>
      </Box>
      <Table<BannerData>
        columns={[
          {
            label: 'Active',
            width: 40,
          },
          {
            label: 'Relevant Path',
            width: 160,
          },
          {
            label: 'Banner',
          },
          {
            label: '',
            width: 120,
          },
        ]}
        rows={publicConfig.banners ?? []}
        rowKey="message"
        title="Banners"
        rowContent={(row, index) => (
          <React.Fragment>
            <TableCell>
              <Checkbox checked={row.enabled} onClick={() => handleCheckboxClick(index)} />
            </TableCell>
            <TableCell>{row.relevantPath ?? 'All'}</TableCell>
            <TableCell>
              <Alert
                sx={{
                  alignItems: 'center',
                }}
                variant={row.variant}
                severity={row.severity ?? 'info'}
                icon={<Icon icon={(row.icon as IconNames) ?? 'info'} />}
              >
                <Typography>{row.message}</Typography>
              </Alert>
            </TableCell>
            <TableCell>
              <IconButton size="small">
                <Icon
                  icon="copy"
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  onClick={() => handleCopyBanner(index)}
                />
              </IconButton>
              <IconButton size="small">
                <Icon
                  icon="edit"
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  onClick={() => handleEditBanner(index)}
                />
              </IconButton>
              <IconButton
                size="small"
                sx={{
                  color: theme.palette.error.main,
                }}
                onClick={() => handleDeleteBanner(index)}
              >
                <Icon
                  icon="trash"
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
              </IconButton>
            </TableCell>
          </React.Fragment>
        )}
      />
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Box width={400} p={3} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h2">{bannerIndexToEdit !== null ? 'Edit' : 'New'} Banner</Typography>
          <Button onClick={handleSaveBanner}>Save</Button>
        </Box>
        <Box display="flex" flexDirection="column" gap={3} p={2}>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography>Active</Typography>
            <Checkbox
              checked={drawerBannerData.enabled}
              onChange={(e) =>
                setDrawerBannerData({ ...drawerBannerData, enabled: e.target.checked })
              }
            />
          </Box>

          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography>Severity</Typography>
            <Select
              size="small"
              variant="filled"
              value={drawerBannerData.severity}
              onChange={(event) => {
                setDrawerBannerData({
                  ...drawerBannerData,
                  severity: event.target.value as AlertColor,
                });
              }}
              disableUnderline
              MenuProps={menuProps(theme)}
            >
              <MenuItem value="info">
                <Typography variant="label" color={theme.palette.info.main}>
                  Info
                </Typography>
              </MenuItem>
              <MenuItem value="success">
                <Typography variant="label" color={theme.palette.success.main}>
                  Success
                </Typography>
              </MenuItem>
              <MenuItem value="warning">
                <Typography variant="label" color={theme.palette.warning.main}>
                  Warning
                </Typography>
              </MenuItem>
              <MenuItem value="error">
                <Typography variant="label" color={theme.palette.error.main}>
                  Error
                </Typography>
              </MenuItem>
            </Select>
          </Box>

          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography>Icon</Typography>
            <Select
              size="small"
              variant="filled"
              value={drawerBannerData.icon ?? 'info'}
              onChange={(event) => {
                setDrawerBannerData({
                  ...drawerBannerData,
                  icon: event.target.value as IconNames,
                });
              }}
              disableUnderline
              MenuProps={menuProps(theme)}
            >
              {iconNames.map((icon) => (
                <MenuItem key={icon} value={icon}>
                  <Icon
                    icon={icon}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography>Variant</Typography>
            <Select
              size="small"
              variant="filled"
              value={drawerBannerData.variant ?? 'default'}
              onChange={(event) => {
                setDrawerBannerData({
                  ...drawerBannerData,
                  variant:
                    event.target.value === 'default'
                      ? undefined
                      : (event.target.value as 'filled' | 'outlined'),
                });
              }}
              disableUnderline
              MenuProps={menuProps(theme)}
            >
              {['default', 'filled', 'outlined'].map((variant) => (
                <MenuItem key={variant} value={variant}>
                  <Typography>{variant}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography>Relevant Paths</Typography>
            <TextField
              multiline
              variant="filled"
              value={drawerBannerData.relevantPath}
              onChange={(e) =>
                setDrawerBannerData({
                  ...drawerBannerData,
                  relevantPath: e.target.value.trim().length ? e.target.value.trim() : undefined,
                })
              }
            />
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>Message</Typography>
            <TextField
              multiline
              variant="filled"
              value={drawerBannerData.message}
              onChange={(e) =>
                setDrawerBannerData({ ...drawerBannerData, message: e.target.value })
              }
            />
          </Box>
        </Box>
      </Drawer>
    </Wrapper>
  );
};
